import Mock from '../mock';

const database = {
  information: {
    name: 'Shane Dyer',
    aboutContent:
      'I’m a passionate web developer with 8+ years experience in the digital industry. I love writing beautiful code, and can help you create all kinds of web solutions.',
    age: '',
    phone: '',
    email: 'shane.dyer01@gmail.com',
    address: 'Auckland, New Zealand',
    freelanceStatus: 'Available',
    socialLinks: {
      email: 'shane.dyer01@gmail.com',
      facebook: '',
      linkedin: 'https://www.linkedin.com/in/shane-dyer/',
      github: 'https://github.com/shane-dyer',
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '',
    cvfile: '',
  },
  services: [
    {
      title: 'Web Development',
      icon: 'code',
      details:
        'Creating majestic code since ages ago, all of the sites I create are highly customised, allowing you to choose any features that your heart desires.',
    },
    {
      title: 'Hosting',
      icon: 'cloud',
      details:
        'I offer hosting solutions for all of my clients, but if you’d rather have your website sitting somewhere else, that’s no problem at all.',
    },
    {
      title: 'Consulting',
      icon: 'phone',
      details:
        'Scoping a new project and aren’t quite sure how to go about it? I am available as a consultant for a range of web related services.',
    },
  ],
  reviews: [
    {
      id: 1,
      content:
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.',
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company',
      },
    },
    {
      id: 2,
      content:
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.',
      author: {
        name: 'Susan Yost',
        designation: 'Client',
      },
    },
    {
      id: 3,
      content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client',
      },
    },
  ],
  skills: [
    {
      title: 'HTML/CSS',
      value: 90,
    },
    {
      title: 'Javascript',
      value: 90,
    },
    {
      title: 'React/Redux',
      value: 90,
    },
    {
      title: 'Photoshop',
      value: 60,
    },
  ],
  portfolios: [
    {
      id: 1,
      title: 'hakatours.com',
      subtitle:
        'Haka Tours is one of New Zealand’s leading tourism companies. Built in GatsbyJS with data provided by a custom API, this site features a full ecommerce system.',
      imageUrl: '/images/portfolio-image-1.jpg',
      largeImageUrl: ['/images/portfolio-image-1-lg.jpg'],
      url: 'https://hakatours.com/',
    },
    {
      id: 3,
      title: 'arizto.co.nz',
      subtitle:
        'Real Estate agency startup, this site features a complex appraisal system, sign-up form and account dashboard, all built with React and Redux.',
      imageUrl: '/images/portfolio-image-6.jpg',
      largeImageUrl: ['/images/portfolio-image-6-lg.jpg'],
      url: 'https://arizto.co.nz/',
    },
    {
      id: 4,
      title: 'asbclassic.co.nz',
      subtitle:
        'New Zealand’s premier tennis tournament. Built with SilverStripe CMS, this site featured an integration with the ATP’s live score API.',
      imageUrl: '/images/portfolio-image-2.jpg',
      largeImageUrl: ['/images/portfolio-image-2-lg.jpg'],
      url: 'https://www.asbclassic.co.nz/',
    },
    {
      id: 2,
      title: 'gjgardner.co.nz',
      subtitle:
        'New Zealand’s most trusted home builder. This site features a bespoke property listings/filtering system and account dashboard to manage properties.',
      imageUrl: '/images/portfolio-image-7.jpg',
      largeImageUrl: ['/images/portfolio-image-7-lg.jpg'],
      url: 'https://www.gjgardner.co.nz/',
    },
    {
      id: 5,
      title: 'thedogsharecollective.com',
      subtitle:
        'The Dog Share Collective provides canine companionship solutions for NZ dogs. This site is a single page app built with React.',
      imageUrl: '/images/portfolio-image-8.jpg',
      largeImageUrl: ['/images/portfolio-image-8-lg.jpg'],
      url: 'https://www.thedogsharecollective.com/',
    },
    {
      id: 6,
      title: 'greenacres.co.nz',
      subtitle:
        'Provider of professional home services. Built with SilverStripe CMS, this site also features multiple React forms.',
      imageUrl: '/images/portfolio-image-5-lg.jpg',
      largeImageUrl: ['/images/portfolio-image-5-lg.jpg'],
      url: 'https://www.greenacres.co.nz/',
    },
    {
      id: 7,
      title: 'kernelwealth.co.nz',
      subtitle:
        'Built with React and ChartistJS. An interactive chart to show the user how their investment would’ve performed in past years compared to cash savings.',
      imageUrl: '/images/portfolio-image-3.jpg',
      largeImageUrl: ['/images/portfolio-image-3-lg.jpg'],
      url: 'https://kernelwealth.co.nz/calculator/',
    },
    {
      id: 8,
      title: 'classiccover.co.nz',
      subtitle:
        'Automobile insurer. This site features multiple custom, multi-step forms with save & continue states.',
      imageUrl: '/images/portfolio-image-4.jpg',
      largeImageUrl: ['/images/portfolio-image-4-lg.jpg'],
      url: 'https://www.classiccover.co.nz/',
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: '2014 - 2019',
        position: 'Senior Front End Developer',
        company: 'Isobar New Zealand (formally Little Giant)',
        details:
          'Lead front-end developer on medium to large projects. Collaborated on internal tooling projects and initiatives. Helped mentor junior developers. Agile and scrum workflows.',
      },
      {
        id: 2,
        year: '2012 - 2014',
        position: 'Web Developer',
        company: 'New Image Group Inc.',
        details:
          'Health product and nutritionals provider. Created websites for newly released products. Managed online content for the companies digital presence.',
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: '2010 - 2011',
        graduation: 'Diploma, Web Development',
        university: 'Natcoll Design Technology',
        details: 'Auckland, NZ',
      },
      {
        id: 2,
        year: '2009 - 2010',
        graduation: 'Diploma, Interactive Design',
        university: 'Natcoll Design Technology',
        details: 'Auckland, NZ',
      },
    ],
  },
  contactInfo: {
    phoneNumbers: [],
    emailAddress: ['shane.dyer01@mail.com'],
    address: 'Auckland, New Zealand',
  },
};

Mock.onGet('/api/information').reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet('/api/services').reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet('/api/reviews').reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet('/api/skills').reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet('/api/portfolios').reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet('/api/experience').reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet('/api/blog').reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet('/api/contactinfo').reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});

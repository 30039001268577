import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sectiontitle from './Sectiontitle';

class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    console.log(error.message);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="Something went wrong!" />
            <p>{error.message}</p>
            <Link to="/">Back home</Link>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;

import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import LineIcon from 'react-lineicons';

const isSmallScreen = () => window.matchMedia('(max-width: 1199px)').matches;

function Header() {
  const [information, setInformation] = useState('');
  const [navigationToggler, setNavigationToggler] = useState(false);

  const handleNavigationToggler = () => {
    setNavigationToggler(!navigationToggler);
  };

  const handleNavigationMobile = () => {
    if (isSmallScreen()) {
      setNavigationToggler(!navigationToggler);
    }
  };

  useEffect(() => {
    axios.get('/api/information').then((response) => {
      setInformation(response.data);
    });
  }, []);

  return (
    <nav className={navigationToggler ? 'mi-header is-visible' : 'mi-header'}>
      <button onClick={handleNavigationToggler} className="mi-header-toggler">
        {!navigationToggler ? (
          <LineIcon name="menu" />
        ) : (
          <LineIcon name="close" />
        )}
      </button>

      <div className="mi-header-inner">
        <div className="mi-header-image">
          <Link to="/" onClick={handleNavigationMobile}>
            <img src={information.brandImage} alt="Shane Dyer" />
          </Link>
        </div>

        <ul className="mi-header-menu">
          <li>
            <NavLink exact to="/" onClick={handleNavigationMobile}>
              <span>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" onClick={handleNavigationMobile}>
              <span>About</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/resume" onClick={handleNavigationMobile}>
              <span>Résumé</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/portfolio" onClick={handleNavigationMobile}>
              <span>Portfolio</span>
            </NavLink>
          </li>
        </ul>
        <p className="mi-header-copyright">
          <strong>Shane Dyer.</strong>
          <br />
          All rights reserved.
        </p>
      </div>
    </nav>
  );
}

export default Header;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import BackgroundLines from './BackgroundLines';

function Layout(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }, [pathname]);

  return (
    <div className="mi-wrapper">
      <BackgroundLines />
      <Header />
      {props.children}
    </div>
  );
}

export default Layout;
